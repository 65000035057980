
const track = (data) => {
	if (typeof data === 'string') {
		data = { 'tags': data }
	}
	window.mt('send', 'pageview', data);
}

export { track }

export default window.mt;