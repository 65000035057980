import React, { Fragment } from 'react';
import 'intl';
import { IntlProvider } from 'react-intl';
import translations from '../translations';
import { Redirect } from "react-router-dom";


const browserLocale = (navigator.languages && navigator.languages[0]) ||
	navigator.language ||
	navigator.userLanguage;
const language = browserLocale.substring(0, 2);

const Language = ({ match: { params: { lang } }, children, location: { pathname } }) => {
	if (language !== 'en' && !lang && translations.hasOwnProperty(language)) {
		return <Redirect to={`/${language}${pathname}`} />
  }
  const locale = lang || 'en';
	const messages = translations[locale];
	return (
		<IntlProvider locale={locale} messages={messages} textComponent={Fragment}>
			{children}
		</IntlProvider>
	);
};

export default Language;