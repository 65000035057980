import { inflector } from "./index";

const SOURCE_POINTER_REGEXP = /^\/?data\/(attributes|relationships)\/(.*)/;
const SOURCE_POINTER_PRIMARY_REGEXP = /^\/?data/;
const PRIMARY_ATTRIBUTE_KEY = 'base';

const formatKeys = (item = {}, inflection = 'camelize') => Object.keys(item).reduce((obj, key) => ({
	...obj,
	[inflector(key)[inflection]()]: item[key],
}), {});

const normalize = document => {
	if (document.errors) {
		return errorsArrayToHash(document.errors);
	}
	let data = document.data;
	const { meta = {}, links = {} } = document;
	if (!Array.isArray(data)) {
		data = [data];
	}
	return data.reduce((obj, item) => {
		if (!item) {
			return obj;
		}
		return {
			...obj,
			[item.type]: {
				...obj[item.type],
				[item.id]: {
					id: item.id,
					attributes: formatKeys(item.attributes, 'camelize'),
					relationships: formatKeys(item.relationships, 'camelize')
				}
			},
			links: formatKeys(links, 'camelize'),
			meta: formatKeys(meta, 'camelize')
		}
	}, {});
};

const denormalize = document => {
	let data = ['attributes', 'relationships']
		.reduce((obj, key) => document[key]
			? { ...obj, [key]: formatKeys(document[key], 'decamelize') }
			: obj, { type: document.type });
	return {
		data,
	}
};

function errorsArrayToHash(errors = []) {
	let out = {};

	errors.forEach((error) => {
		if (error.source && error.source.pointer) {
			let key = error.source.pointer.match(SOURCE_POINTER_REGEXP);

			if (key) {
				key = key[2];
			} else if (error.source.pointer.search(SOURCE_POINTER_PRIMARY_REGEXP) !== -1) {
				key = PRIMARY_ATTRIBUTE_KEY;
			}

			if (key) {
				key = inflector(key).camelize();
				out[key] = out[key] || [];
				out[key].push(error.detail || error.title);
			}
		} else {
			out = error;
		}
	});

	return out;
}

export default {
	normalize,
	denormalize,
}