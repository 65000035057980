import { captureException, withScope, init, addBreadcrumb } from '@sentry/browser';

init({
  dsn: "https://14aa8ea6b5f3468aaad8040b85216af1@sentry.io/1358928",
  environment: process.env.NODE_ENV,
});

export const trail = (message, category = 'ui', attributes = {}) => {
  addBreadcrumb({
    message,
    category,
    ...attributes
  })
}

export const error = (err, info = {}) => {
  withScope(scope => {
    Object.keys(info).forEach(key => {
      scope.setExtra(key, info[key]);
    });
    captureException(err);
  });
}

export default {
  error,
  trail,
}