import { uncountable, plurals, irregularPairs, singular } from './inflections';

const BLANK_REGEX = /^\s*$/;
const LAST_WORD_DASHED_REGEX = /([\w/-]+[_/\s-])([a-z\d]+$)/;
const LAST_WORD_CAMELIZED_REGEX = /([\w/\s-]+)([A-Z][a-z\d]*$)/;
const CAMELIZED_REGEX = /[A-Z][a-z\d]*$/;
const STRING_DECAMELIZE_REGEXP = (/([a-z\d])([A-Z])/g);
const STRING_CAMELIZE_REGEXP_1 = (/(-|_|\.|\s)+(.)?/g);
const STRING_CAMELIZE_REGEXP_2 = (/(^|\/)([A-Z])/g);

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);
const camelize = key => {
	return key.replace(STRING_CAMELIZE_REGEXP_1, (match, separator, chr) => {
		return chr ? chr.toUpperCase() : '';
	}).replace(STRING_CAMELIZE_REGEXP_2, (match, separator, chr) => {
		return match.toLowerCase();
	});
};
const decamelize = string => string.replace(STRING_DECAMELIZE_REGEXP, '$1-$2').toLowerCase();

const inflect = (word, typeRules, irregular = {}) => {
	let inflection, substitution, result, lowercase, wordSplit,
		lastWord, isBlank, isCamelized, rule, isUncountable;

	isBlank = !word || BLANK_REGEX.test(word);

	isCamelized = CAMELIZED_REGEX.test(word);

	if (isBlank) {
		return word;
	}

	lowercase = word.toLowerCase();
	wordSplit = LAST_WORD_DASHED_REGEX.exec(word) || LAST_WORD_CAMELIZED_REGEX.exec(word);

	if (wordSplit){
		lastWord = wordSplit[2].toLowerCase();
	}

	isUncountable = ~uncountable.indexOf(lowercase) || ~uncountable.indexOf(lastWord);

	if (isUncountable) {
		return word;
	}

	for (rule in irregular) {
		if (lowercase.match(rule+"$")) {
			substitution = irregular[rule];

			if (isCamelized && irregular[lastWord]) {
				substitution = capitalize(substitution);
				rule = capitalize(rule);
			}

			return word.replace(new RegExp(rule, 'i'), substitution);
		}
	}

	for (let i = typeRules.length, min = 0; i > min; i--) {
		inflection = typeRules[i-1];
		rule = inflection[0];

		if (rule.test(word)) {
			break;
		}
	}

	inflection = inflection || [];

	rule = inflection[0];
	substitution = inflection[1];

	result = word.replace(rule, substitution);

	return result;
};

export default (string) => {
	return {
		decamelize() {
			return decamelize(string);
		},
		camelize() {
			return camelize(string);
		},
		pluralize() {
			return inflect(string, plurals, irregularPairs.reduce((obj, pair) => ({ ...obj, [pair[0]]: pair[1], [pair[1]]: pair[1] }), {}))
		},
		singularize() {
			return inflect(string, singular, irregularPairs.reduce((obj, pair) => ({ ...obj, [pair[0]]: pair[0], [pair[1]]: pair[0] }), {}))
		}
	}
}