import React, { lazy, Suspense, useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import Language from "./Language";
import PageLoader from "../components/PageLoader";
import {Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import {useQuery} from "../utils/urlManager";
import store from "../components/hoc/store";

const SignUp = lazy(() => import('./SignUp'));
const Login = lazy(() => import('./Login'));
const Announcement = lazy(() => import('./Announcement'));
const Certificate = lazy(() => import('./Certificate'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const SetPassword = lazy(() => import('./SetPassword'));
const YoutubeDemo = lazy(() => import('./YoutubeDemo'));
const Oauth = lazy(() => import('./Oauth'));
const Media = lazy(() => import('./Media'));
const Desk = lazy(() => import("./desk"));
const NotFound = lazy(() => import("./404"));

const Main = props => {
	const { match: { path }, store } = props;
	const [loading, setLoading] = useState(true);
	const query = useQuery();
	useEffect(() => {
		// anonymous user
		if (query.get('a') === '1') {
			if (Cookie.get('token')) {
				setLoading(false);
				return;
			}
			store.createRecord('users', {
				provider: 'anonymous'
			}).then(() => setLoading(false))
				.catch(() => setLoading(false))
		} else {
			setLoading(false);
		}
	}, [query, store]);

	if (loading) {
		return <PageLoader />;
	}

	return (
		<Language {...props} children={(
			<Suspense fallback={<PageLoader />}>
				<Switch>
					<Redirect exact from={`${path}/`} to="desk"/>
					<Route path={`${path}/:type/media`} component={Media}/>
					<Route path={`${path}/login`} component={Login}/>
					<Route path={`${path}/oauth`} component={Oauth}/>
					<Route path={`${path}/youtube-demo`} component={YoutubeDemo}/>
					<Route path={`${path}/sign-up`} component={SignUp}/>
					<Route path={`${path}/reset-password`} component={ResetPassword}/>
					<Route path={`${path}/set-password/:token`} component={SetPassword}/>
					<PrivateRoute path={`${path}/desk`} component={Desk}/>
					<Route path={`${path}/announcement/:alias`} component={Announcement}/>
					<Route path={`${path}/certificate/:alias`} component={Certificate}/>
					<Route path={`${path}/404`} component={NotFound}/>
				</Switch>
			</Suspense>
		)}
		/>
	)
};

export default store(Main);
