import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

ReactDOM.render(
	<Elements stripe={stripePromise}>
		<App />
	</Elements>,
	document.getElementById('root')
);
unregister();
