import React, { Component } from 'react';
import { normalizer } from "../../system";
import Cookie from 'js-cookie';
import { createUrl } from '../../utils/urlManager'
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const { denormalize, normalize } = normalizer;

const request = (path, options) => {
	const params = options.params || {};
	const qs = Object.keys(params)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
		.join('&');
	const isAbsolute = path.indexOf('//') === 0 || path.indexOf('://') !== -1;
	const url = (isAbsolute ? '' : BASE_URL) + path + (qs && '?' + qs);
	const headers = {
		'Content-Type': 'application/vnd.api+json',
		'Accept': 'application/vnd.api+json'
	};
	const token = Cookie.get('token');
	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}
	const body = options.body && JSON.stringify(denormalize(options.body));
	return fetch(url, { ...options, headers, body })
		.then(res => {
			if (res.headers.has('Set-Token')) {
				Cookie.set('token', res.headers.get('Set-Token'));
			}
			if (res.status === 401) {
				Cookie.remove('token');
				window.location.href = createUrl('/login');
				return;
			}
			if (res.status === 429) {
				alert('Please try again later. We limit how often you can do certain things to protect our platform from abuse. Tell us if you think we made a mistake.');
			}
			return res.json()
				.then(normalize)
				.then(data => {
					if (res.status >= 400) {
						return Promise.reject(data);
					}
					return Promise.resolve(data);
				})
		})
};

const modelByUrl = (url) => (
	request(url, { method: 'GET' })
		.then(res => {
			const { links, meta = {}, ...data} = res;
			const key = Object.keys(data)[0];
			const ids = Object.keys(data[key] || {});
			return shapeResponse(res, key, meta.totalCount ? null : ids[0])
		})
)

const createModel = data => {
	const { id, attributes, relationships } = data;
	return {
		id,
		...attributes,
		related: (relation) => {
			const { links: { related } = {} } = relationships[relation];
			if (!related) {
				return Promise.reject({})
			}
			return modelByUrl(related.href || related);
		}
	}
};

const shapeResponse = (data, key, id) => {
	const records = data[key] || {};
	if (id && !records[id]) {
		return createModel(Object.values(records)[0]);
	}
	if (id) {
		return createModel(records[id]);
	}
	return {
		links: data.links && Object
			.keys(data.links)
			.reduce((acc, key) => ({
				...acc,
				[key]: () => modelByUrl(data.links[key].href || data.links[key])
			}), {}),
		meta: data.meta,
		data: Object.keys(records).map((key) => createModel(records[key]), {})
	};
};

const store = {
	updateRecord(type, id, attributes, options) {
		return request(`${type}/${id}`, {...options, method: "PATCH", body: { type, attributes }})
			.then(data => shapeResponse(data, type, id));
	},
	createRecord(type, attributes, options) {
		return request(type, {...options, method: "POST", body: { type, attributes }})
			.then(data => shapeResponse(data, type))
			.then(({ data: [model] }) => model);
	},
	findRecord(type, id, options) {
		return request(`${type}/${id}`, {...options, method: "GET"})
			.then(data => shapeResponse(data, type, id));
	},
	findAll(type, options) {
		return request(type, {...options, method: "GET"})
			.then(data => shapeResponse(data, type));
	}
};

export default (WrappedComponent) => {
	return class extends Component {

		render() {
			const props = {
				...this.props,
				store,
			};
			return <WrappedComponent {...props} />
		}
	}
}

export { store }
