import { useLocation } from 'react-router-dom';

export const createUrl = (path, params = {}, lang = 'en') => {
  if (!path) {
    return '';
  }

  if (!path.startsWith('/')) {
    path = `/${path}`;
  }

  const url = Object.keys(params)
    .reduce((acc, key) => acc.replace(`:${key}`, params[key]), path);

  if (!lang || lang === 'en') {
    return url;
  }

  return `/${lang}${url}`;
}

export const useQuery = () => new URLSearchParams(useLocation().search);

export const queryToHash = (query) => {
  let hash = {};
  query.split('&').forEach(function(kv) {
    const [key, value] = kv.split('=');
    hash[key] = decodeURIComponent(value);
  })
  return hash;
}