import React, { Component } from 'react';
import log from '../utils/logger';
import { showReportDialog } from '@sentry/browser';
import { Button } from 'semantic-ui-react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    log.error(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <Button onClick={() => showReportDialog()}>Report feedback</Button>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;