import React from 'react';
import { createBrowserHistory } from 'history';
import './App.css';
import 'fomantic-ui-css/semantic.min.css';
import {Router, Route} from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import mt from "./utils/mt";
import MediaContainer from "./components/MediaContainer";
import Main from "./routes";

const history = createBrowserHistory();
history.listen(() => {
	mt('send', 'pageview');
});

const App = () => (
	<ErrorBoundary>
		<MediaContainer>
			<Router history={history}>
				<Route path="/:lang(en|ru)?" component={Main} />
			</Router>
		</MediaContainer>
	</ErrorBoundary>
)

export default App;
